<template>
  <div>
    <v-footer height="auto" class="mt-12 pa-12 not-printable" v-if="!inIFrame" color="#005267" dark>
      <v-row dense>
        <v-col cols="12" md="8" offset-md="4" lg="10" offset-lg="2">
          <v-row>
            <v-col cols="12" md="6" class="pr-16">
              <v-img
                src="https://vblimages.blob.core.windows.net/images/6625e2fc-06ea-4c81-92d6-f2b9ff048bca.png"
                width="150"
                contain
              ></v-img>
              p1440 aims to unite, empower, and inspire through the transformative sport of beach volleyball.
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4">
                  <div class="white--text smoothy">About</div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/our-story/" class="ml-n3" style="text-transform: none;">
                      Our Story
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/impact/" class="ml-n3" style="text-transform: none;">
                      Impact
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/our-partners/" class="ml-n3" style="text-transform: none;">
                      Our Partners
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/affiliate-clubs/" class="ml-n3" style="text-transform: none;">
                      Affiliate Clubs
                    </v-btn>
                  </div>
                  <div class="mt-6 smoothy white--text">
                    <a href="https://p1440.org/get-involved/" class="link">Get Involved</a>
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div  class="white--text smoothy">Resources</div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/get-started/" class="ml-n3" style="text-transform: none;">
                      Get Started
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/affiliate-portal/" class="ml-n3" style="text-transform: none;">
                      Affiliate Portal
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/coaches/" class="ml-n3" style="text-transform: none;">
                      Coaches
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/recruitment/" class="ml-n3" style="text-transform: none;">
                      Recruitment
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/indoor-to-beach/" class="ml-n3" style="text-transform: none;">
                      Indoor to Beach
                    </v-btn>
                  </div>
                  <div class="mt-6 smoothy white--text">
                    News & Events
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://www.prweb.com/releases/us_sports_camps_launches_beach_volleyball_camps_with_p1440/prweb19349803.htm" class="ml-n3" style="text-transform: none;">
                      US Sports Camp
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="white--text smoothy">Programs</div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/futures-tour/" class="ml-n3" style="text-transform: none;">
                      Futures Tour
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://play.p1440.com/tournaments/upcoming?u=false&p=2&ipp=12" class="ml-n3" style="text-transform: none;">
                      Regional Events
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://play.p1440.com/tournament/8757?tab=information" class="ml-n3" style="text-transform: none;">
                      National Championship
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/fab50/" class="ml-n3" style="text-transform: none;">
                      fab50
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://www.ussportscamps.com/volleyball/nike/p1440-nike-beach-volleyball-camp-sand-club" class="ml-n3" style="text-transform: none;">
                      Clinics & Camps
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/coast-vs-coast-collegiate-invitationals/" class="ml-n3" style="text-transform: none;">
                      Coast vs. Coast Collegiate Invitationals
                    </v-btn>
                  </div>
                  <div>
                    <v-btn color="white" text small href="https://p1440.org/adult-national-league/" class="ml-n3" style="text-transform: none;">
                      Adult National League
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="caption">
              © Copyright {{(new Date()).getFullYear()}} platform1440 LLC. All rights reserved.
            </v-col>
            <v-col cols="12" style="font-size: x-small;" class="mt-4">
              Powered by VolleyballLife.com
            </v-col>
            <v-col cols="12" style="font-size: x-small;">
              Live Updates: {{liveUpdateStatus}}
            </v-col>
            <v-col cols="12" class="mt-4">
              <organization-create v-if="user"></organization-create>
            </v-col>
            <!-- indoor club wizard -->
            <!-- <v-col cols="12" class="mt-4">
              <indoor-club-wizard v-if="user"></indoor-club-wizard>
            </v-col> -->
            <v-col class="text-center" cols="12" v-if="user && user.pages.length">
              <v-btn @click="toggleView" color="color3 color3Text--text">
                <v-icon class=mr-3>fas fa-eye</v-icon>
                Switch to {{altView}} view
              </v-btn>
            </v-col>
            <v-col class="text-center" cols="12" v-if="user && user.id === 1">
              {{$vuetify.breakpoint.name}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer height="auto" class="not-printable footer-2" color="rgb(17,20,37)" dark>
      <v-container fluid>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrganizationCreate from '@/components/Organization/Create.vue'

export default {
  props: ['liveUpdateStatus', 'altView', 'toggleView', 'inIFrame'],
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    OrganizationCreate
  },
  created () {
    const head = document.getElementsByTagName('HEAD')[0]
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://use.typekit.net/nee7zvv.css'
    head.appendChild(link)
  }
}
</script>

<style>
@font-face {
  font-family: 'gilroy';
  src: url('../assets/gilroy-bold.woff2') format('woff2'),
       url('../assets/gilroy-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'gilroy-bold';
  src: url('../assets/gilroy-bold.woff2') format('woff2'),
       url('../assets/gilroy-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/gilroy-regular.woff2') format('woff2'),
       url('../assets/gilroy-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body,
.title,
.application,
.v-application {
  font-family: 'gilroy',sans-serif !important;
}
.title {
  font-family: 'gilroy',sans-serif !important;
  font-weight: 800
}
.v-footer v-btn {
  color: #fff !important;
  opacity: 0.7;
  text-decoration: none !important;
  transition: all 200ms linear;
}
.v-footer .link {
  color: #fff !important;
  text-decoration: none !important;
}
.v-footer v-btn:hover {
  color: #fff !important;
  opacity: 1;
}
.underlined {
  border-bottom: 1px solid #33BFC8 !important;
}
.footer-2 a {
  color: #000 !important;
  text-transform: uppercase;
}
.smoothy {
  font-family: smoothy;
  font-size: 20px;
}
</style>
